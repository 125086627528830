import { EyeIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { ShortenedUUID } from '@/components/shortened-uuid';
import type { BatchResponse } from '@/hooks/use-batches-nested-query';

import type { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<BatchResponse>[] = [
  {
    accessorKey: 'lot',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Lot" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('lot')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Batch Id" />
    ),
    cell: ({ row }) => {
      return <ShortenedUUID uuid={row.getValue('id')} />;
    },
  },
  {
    accessorKey: 'tidCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tags" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('tidCount')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'producedAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Produced At" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="font-medium">{row.getValue('producedAt')}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'view',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="View Detail" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <Link to={`batches/${row.original.id}`}>
            <EyeIcon className="h-4 w-4" />
          </Link>
        </div>
      );
    },
  },
];

import { Cross2Icon } from '@radix-ui/react-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CirclePlus, ListFilter, Search } from 'lucide-react';
import { useDebounceCallback } from 'usehooks-ts';

import { DataTableViewOptions } from '@/components/data-table-view-options';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { selectDialog } from '@/store/dialog/dialog.slice';

import { CreateBatchDialog } from '../create-batch-dialog/create-batch-dialog';

import type { Table } from '@tanstack/react-table';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const { authAppTemplates } = useFlags();
  const isFiltered = table.getState().columnFilters.length > 0;

  const debouncedFilter = useDebounceCallback(
    (value: string) => table.getColumn('lot')?.setFilterValue(value),
    500,
  );

  const dispatch = useAppDispatch();
  function createBatchDialogHandler() {
    dispatch(selectDialog('create-batch'));
  }

  if (authAppTemplates) {
    return (
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-row space-x-4">
            <div className="flex flex-1 items-center space-x-2 relative">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground pointer-events-none" />
              <Input
                className="h-8 w-[150px] lg:w-[250px] pl-7 rounded-lg"
                defaultValue={
                  (table.getColumn('lot')?.getFilterValue() as string) || ''
                }
                onChange={(e) => debouncedFilter(e.target.value)}
                placeholder="Search..."
              />
              {isFiltered ? (
                <Button
                  className="h-8 px-2 lg:px-3"
                  onClick={() => {
                    table.resetColumnFilters();
                  }}
                  variant="ghost"
                >
                  Reset
                  <Cross2Icon className="ml-2 h-4 w-4" />
                </Button>
              ) : null}
            </div>
            <Button
              className=" hidden h-8 lg:flex rounded-lg"
              size="sm"
              type="button"
              variant="outline"
            >
              <ListFilter className="h-4 w-4 mr-2" />
              Filter
            </Button>
          </div>
          <Button
            className="hidden h-8 lg:flex rounded-lg"
            onClick={createBatchDialogHandler}
            size="sm"
            type="button"
          >
            <CirclePlus className="h-4 w-4 mr-2" />
            New Batch
          </Button>
        </div>
        <CreateBatchDialog />
      </>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          className="h-8 w-[150px] lg:w-[250px]"
          onChange={(event) =>
            table.getColumn('lot')?.setFilterValue(event.target.value)
          }
          placeholder="Filter batches..."
          value={(table.getColumn('lot')?.getFilterValue() as string) ?? ''}
        />
        {/* {table.getColumn('productId') && (
          <DataTableFacetedFilter
            column={table.getColumn('productId')}
            options={statuses}
            title="Product"
          />
        )} */}
        {isFiltered ? (
          <Button
            className="h-8 px-2 lg:px-3"
            onClick={() => {
              table.resetColumnFilters();
            }}
            variant="ghost"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        ) : null}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}

import { MoreHorizontal } from 'lucide-react';

import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { ShortenedUUID } from '@/components/shortened-uuid';
import type { BatchResponse } from '@/hooks/use-batches-nested-query';

import type { ColumnDef } from '@tanstack/react-table';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

export const batchColumns: ColumnDef<BatchResponse>[] = [
  {
    accessorKey: 'lot',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Lot" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('lot')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Batch Id" />
    ),
    cell: ({ row }) => {
      return <ShortenedUUID uuid={row.getValue('id')} />;
    },
  },
  {
    accessorKey: 'tidCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tags" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('tidCount')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'producedAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Produced" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="font-medium">{row.getValue('producedAt')}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="font-medium">
            <Badge variant={'outline'}>Active</Badge>
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'view',
    header: () => null,
    cell: () => {
      return (
        <div className="flex space-x-2">
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon">
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">Open menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>View tags</DropdownMenuItem>
              <DropdownMenuItem>Edit show more</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];

import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

// Template schema definition
export const TemplateSchema = z.object({
  headingFontFamily: z.string().nullable().optional(),
  id: z.string().uuid(),
  name: z.string(),
  primaryColor: z.string(),
  privacyPolicyUrl: z.string().url(),
  regularTextFontFamily: z.string().nullable().optional(),
  secondaryColor: z.string().optional(),
  seeMore: z.string().nullable().optional(),
  tryAgainFooterText: z.string().nullable().optional(),
  tryAgainMessage: z.string().nullable().optional(),
  status: z.boolean().optional(),
  bodyTextColor: z.string(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export const TemplateWithUsageCountSchema = TemplateSchema.extend({
  usageCount: z.number(),
});

export type Template = z.infer<typeof TemplateSchema>;
export type TemplateWithUsageCount = z.infer<
  typeof TemplateWithUsageCountSchema
>;

import { MoreHorizontal, Paintbrush } from 'lucide-react';
import { useState } from 'react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useTemplatesQuery } from '@/hooks/use-templates-query';

import type { PaginationState } from '@tanstack/react-table';

type TTemplatesHorizontalList = {
  productId: string;
};

export default function TemplatesHorizontalList({
  productId,
}: TTemplatesHorizontalList) {
  const filters = useSearchFilters();

  const [pagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data } = useTemplatesQuery({
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
    filters,
  });

  return (
    <Card className="p-4 rounded-xl relative">
      {/* Added relative positioning */}
      <h2 className="absolute -top-3 left-4 bg-white text-sm px-2">
        Templates
      </h2>
      {/* Positioned absolutely */}
      <div className="flex justify-end mb-4">
        {/* Added mt-4 for spacing */}
        <Button className="hidden h-8 lg:flex rounded-lg">
          <Paintbrush className="w-4 h-4 mr-2" />
          New template
        </Button>
      </div>
      <ScrollArea className="w-full whitespace-nowrap rounded-lg">
        <div className="flex w-full space-x-4 pb-4">
          {data?.templates.map((template) => (
            <Card className="w-[200px] shrink-0 rounded-lg" key={template.id}>
              <CardContent className="p-0">
                <div
                  className="relative aspect-square"
                  style={{
                    background: `linear-gradient(135deg, ${template.primaryColor}, ${template.bodyTextColor})`,
                  }}
                >
                  {template.id === productId ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Badge
                        className="bg-background/80 backdrop-blur-sm z-10"
                        variant="secondary"
                      >
                        Active
                      </Badge>
                    </div>
                  ) : null}
                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger asChild>
                      <Button
                        className="absolute right-2 top-2 rounded-lg"
                        size="icon"
                        variant="outline"
                      >
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem>Make active</DropdownMenuItem>
                      <DropdownMenuItem>Edit</DropdownMenuItem>
                      <DropdownMenuItem>Duplicate</DropdownMenuItem>
                      <DropdownMenuItem>Delete</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="p-2">
                  <p className="text-sm">{template.name}</p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </Card>
  );
}

import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { paginationSchema } from '@/data/pagination-schema';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Template } from '@/schemas/template';
import { TemplateSchema } from '@/schemas/template';

import { useAppSelector } from './use-app-selector';

const TemplateListApiResponseSchema = createApiResponseSchema(
  z.array(TemplateSchema),
  paginationSchema,
);

interface TemplatesParams {
  brandId?: string;
}

export type WithPaginationAndFilters<TParam> = TParam & {
  pagination?: {
    skip?: number;
    take?: number;
  };
  filters?: {
    id: string;
    value: unknown;
  }[];
};

async function getTemplates({
  brandId,
  filters,
  pagination,
}: WithPaginationAndFilters<TemplatesParams>) {
  const response = await api.get<Template[]>(
    apiPaths.TEMPLATES({ brandId: brandId ?? '' }),
    {
      params: { ...pagination, filters },
    },
  );

  try {
    const templates = TemplateListApiResponseSchema.parse(response.data);
    return { templates: templates.data, pagination: templates.pagination };
  } catch (error) {
    console.error('Parsing error:', error, response.data);
    return { templates: [], pagination: {} };
  }
}

export function useTemplatesQuery(
  params: WithPaginationAndFilters<TemplatesParams> = {
    pagination: { take: 10, skip: 0 },
    filters: [],
  },
) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: [
      'templates',
      selectedBrand?.id,
      params.filters,
      params.pagination,
    ],
    queryFn: () =>
      getTemplates({
        brandId: selectedBrand?.id,
        filters: params.filters,
        pagination: params.pagination,
      }),
    enabled: Boolean(selectedBrand?.id),
  });
}

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBatchesNestedQuery } from '@/hooks/use-batches-nested-query';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';
import { DataTable } from '../data-table/batch-table';
import { batchColumns } from '../data-table/product-batches/product-batch-columns';

export default function ProductBatches() {
  const { productId } = useParams() as { productId: string };

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const batchesQuery = useBatchesNestedQuery({
    productId,
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
    filters: columnFilters,
  });
  const rowCount = batchesQuery.data?.pagination?.total ?? 0;

  return (
    <DataTable
      columnFilters={columnFilters}
      columns={batchColumns}
      data={batchesQuery.data?.batches ?? []}
      isLoading={batchesQuery.isLoading}
      onColumnFiltersChange={setColumnFilters}
      onPaginationChange={setPagination}
      pagination={pagination}
      rowCount={rowCount}
    />
  );
}

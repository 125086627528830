import { useCallback, useMemo } from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

import { BatchForm, BatchFormData } from '@/components/forms/batch-form';
import { useCreateBatchMutation } from '@/hooks/use-create-batch-mutation';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { selectDialog } from '@/store/dialog/dialog.slice';
import { selectProduct } from '@/store/products/products.slice';

export function CreateBatchDialog() {
  const createBatchMutation = useCreateBatchMutation();
  const dispatch = useAppDispatch();
  const selectedDialog = useAppSelector((state) => state.dialog.selected);

  const createBatchHandler = useCallback(
    (batch: BatchFormData) => {
      createBatchMutation.mutate(batch, {
        onSuccess: () => {
          dispatch(selectDialog());
        },
      });
    },
    [createBatchMutation, dispatch],
  );

  const productDialogData = useMemo(
    () =>
      ({
        'create-batch': {
          header: 'Create Manual Batch',
          description:
            'Add manually a new batch assigned to your product items',
          component: <BatchForm onCreateBatch={createBatchHandler} />,
        },
      }) as const,
    [createBatchHandler],
  );

  const closeDialogHandler = useCallback(() => {
    dispatch(selectDialog());
    dispatch(selectProduct());
  }, [dispatch]);

  return (
    <Dialog
      onOpenChange={closeDialogHandler}
      open={selectedDialog !== undefined}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {selectedDialog
              ? productDialogData[selectedDialog as 'create-batch'].header
              : null}
          </DialogTitle>
          <DialogDescription>
            {selectedDialog
              ? productDialogData[selectedDialog as 'create-batch'].description
              : null}
          </DialogDescription>
        </DialogHeader>
        {selectedDialog
          ? productDialogData[selectedDialog as 'create-batch'].component
          : null}
      </DialogContent>
    </Dialog>
  );
}

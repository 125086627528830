interface CompanyByIdPath {
  companyId: string;
}
interface CatalogItemByIdPath {
  catalogItemId: string;
}

interface BrandByIdPath {
  brandId: string;
}

interface ApiKeyByIdPath extends CompanyByIdPath {
  apiKeyId: string;
}

interface OrderByIdPath extends BrandByIdPath {
  orderId: string;
}

interface NamespaceByIdPath extends BrandByIdPath {
  namespaceId: string;
}

interface MetafieldByIdPath extends NamespaceByIdPath {
  metafieldId: string;
}

interface BatchByIdPath extends BrandByIdPath {
  batchId: string;
}

interface TidByIdPath extends BrandByIdPath {
  tidId: string;
}

interface ProductByIdPath extends BrandByIdPath {
  productId: string;
}

interface BatchByIdNestedPath extends ProductByIdPath {
  batchId: string;
}

interface TidByIdNestedPath extends BatchByIdNestedPath {
  tidId: string;
}

export const apiPaths = {
  BRANDS: 'brands',
  BRAND_BY_ID: ({ brandId }: BrandByIdPath) => `brands/${brandId}`,
  BRAND_LOGO_BY_ID: ({ brandId }: BrandByIdPath) => `brands/${brandId}/logo`,
  COMPANIES: 'companies',
  COMPANY_BY_ID: ({ companyId }: CompanyByIdPath) => `companies/${companyId}`,
  COMPANY_LOGO_BY_ID: ({ companyId }: CompanyByIdPath) =>
    `companies/${companyId}/logo`,
  CATALOG: 'catalog',
  CATALOG_ITEM_BY_ID: ({ catalogItemId }: CatalogItemByIdPath) =>
    `catalog/${catalogItemId}`,
  INDICATORS: ({ brandId }: BrandByIdPath) => `brands/${brandId}/indicators`,
  SCANS: ({ brandId }: BrandByIdPath) => `brands/${brandId}/scans`,
  API_KEYS: ({ brandId }: BrandByIdPath) => `brands/${brandId}/api-keys`,
  API_KEY_BY_ID: ({ companyId, apiKeyId }: ApiKeyByIdPath) =>
    `companies/${companyId}/api-keys/${apiKeyId}`,
  NAMESPACES: ({ brandId }: BrandByIdPath) => `brands/${brandId}/namespaces`,
  METAFIELDS: ({ brandId, namespaceId }: NamespaceByIdPath) =>
    `brands/${brandId}/namespaces/${namespaceId}/metafields`,
  METAFIELD_BY_ID: ({ brandId, namespaceId, metafieldId }: MetafieldByIdPath) =>
    `brands/${brandId}/namespaces/${namespaceId}/metafields/${metafieldId}`,
  PRODUCTS: ({ brandId }: BrandByIdPath) => `brands/${brandId}/products`,
  PRODUCT_BY_ID: ({ brandId, productId }: ProductByIdPath) =>
    `brands/${brandId}/products/${productId}`,
  PRODUCT_IMAGE_BY_ID: ({ brandId, productId }: ProductByIdPath) =>
    `brands/${brandId}/products/${productId}/image`,
  ORDERS: ({ brandId }: BrandByIdPath) => `brands/${brandId}/orders`,
  ORDER_BY_ID: ({ brandId, orderId }: OrderByIdPath) =>
    `brands/${brandId}/orders/${orderId}`,
  BULK_UPLOAD: ({ brandId, orderId }: OrderByIdPath) =>
    `brands/${brandId}/orders/${orderId}/bulk`,
  BATCHES_NESTED: ({ brandId, productId }: ProductByIdPath) =>
    `brands/${brandId}/products/${productId}/batches`,
  BATCHES: ({ brandId }: BrandByIdPath) => `brands/${brandId}/batches`,
  BATCH_BY_ID: ({ brandId, batchId }: BatchByIdPath) =>
    `brands/${brandId}/batches/${batchId}`,
  BATCH_BY_ID_NESTED: ({ brandId, productId, batchId }: BatchByIdNestedPath) =>
    `brands/${brandId}/products/${productId}/batches/${batchId}`,
  TIDS_NESTED: ({ brandId, productId, batchId }: BatchByIdNestedPath) =>
    `brands/${brandId}/products/${productId}/batches/${batchId}/tids`,
  TIDS_BY_ID_NESTED: ({
    brandId,
    productId,
    batchId,
    tidId,
  }: TidByIdNestedPath) =>
    `brands/${brandId}/products/${productId}/batches/${batchId}/tids/${tidId}`,
  TIDS: ({ brandId }: BrandByIdPath) => `brands/${brandId}/tids`,
  AVAILABLE_ROLLS: ({ brandId }: BrandByIdPath) =>
    `brands/${brandId}/tids/available-rolls`,
  TIDS_BY_ID: ({ brandId, tidId }: TidByIdPath) =>
    `brands/${brandId}/tids/${tidId}`,
  TEMPLATES: ({ brandId }: BrandByIdPath) => `brands/${brandId}/templates`,
};
